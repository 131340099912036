import { FC, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import cn from 'classnames';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './UsedBy.module.scss';

type UsedByProps = {
  darkTheme?: boolean;
  title?: string;
  usedBy: {
    icon: {
      data: {
        attributes: {
          url: string;
        };
      };
    };
    name: string;
  }[];
};

const UsedBy: FC<UsedByProps> = ({ usedBy, darkTheme, title }) => {
  const [sliderSettings, setSliderSettings] = useState({
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    arrows: false,
    draggable: true,
    swipe: true,
    swipeToSlide: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    touchThreshold: 100,
    observeParents: true,
    observeSlideChildren: true,
  });

  useEffect(() => {
    const updateSettingsForMobile = () => {
      if (window.innerWidth <= 960) {
        setSliderSettings(prevSettings => ({
          ...prevSettings,
          speed: 4000,
        }));
      } else {
        setSliderSettings(prevSettings => ({
          ...prevSettings,
          speed: 5000,
        }));
      }
    };

    updateSettingsForMobile();

    window.addEventListener('resize', updateSettingsForMobile);

    return () => {
      window.removeEventListener('resize', updateSettingsForMobile);
    };
  }, []);

  const sliderRef = useRef(null);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        sliderRef.current.slickPause();
      } else {
        sliderRef.current.slickPlay();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <section
      className={cn(styles.usedBy, {
        [styles.darkTheme]: darkTheme,
      })}
    >
      <h2 className={styles.title}>{title}</h2>
      {usedBy && usedBy.length > 0 ? (
        <Slider {...sliderSettings} className={styles.slider} ref={sliderRef}>
          {[...usedBy, ...usedBy].map((item: any, index: number) => (
            <div key={index} className={styles.usedByItem}>
              <img
                className={styles.img}
                src={`${process.env.NEXT_PUBLIC_STRAPI}${darkTheme ? item.dark_icon.data.attributes.url : item.icon.data.attributes.url}`}
                alt={item.name}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <p>No data available</p>
      )}
    </section>
  );
};

export default UsedBy;
